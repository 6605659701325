// MASTER API URL's

export const API_MASTER_SOCIAL_LOGIN = "/users/accounts/social/login/token/";
export const API_MASTER_GET_STATES_LIST = "/master/get_state/";
export const API_MASTER_GET_GRADE = "/master/get_grade/";
export const API_MASTER_GET_MESSAGE = "/master/get_special_message";


export const GET_WIZFIT_SONG="/master/get_wizfit_song/";
export const GET_RESOURCES_CATEGORYLIST="/master/get_resource_category_list/";
export const GET_RESOURCES_SUBCATEGORYLIST="/master/get_resource_subcategory_list/";
export const GET_WIZFIT_DAILY_REPORT="/campaign/campaign_statistics_report/";
export const GET_WIZFIT_DAILY_CAMPAIGN_STATISTICS_REPORT="/admins/wizfit_daily_performance_report/";
export const GET_DEFAULT_GOAL="/master/get_default_goal/"


//USER API URL's
export const API_USER_GET_SALES_REP_LIST = "/users/get_sales_rep_list/";
export const API_USER_GET_EVENT_SUPPORT_LIST ="/users/get_event_support_staff_list/";

export const API_USER_GET_DISTRICT_LIST = "/district/get_district_list/";

export const API_DISTRICT_OVERVIEW_ANIMATION =
  "/district/special_district_overview/";

// AUTHENTICATION API URL's
export const API_ADMIN_LOGIN = "/users/login/";
export const API_ADMIN_FORGOT_PASSWORD = "/users/forgot_password/";
export const API_ADMIN_RESET_PASSWORD = "/users/change_password/";

// DISTRICT MANAGEMENT API URL's
export const API_ADMIN_DISTRICT_MANAGEMENT_LIST = "/district/list/";
export const API_ADMIN_ADD_DISTRICT = "/district/detail/";
export const API_ADMIN_UPDATE_DISTRICT = "/district/detail/";
export const API_ADMIN_GET_DISTRICT_DETAIL = "/district/detail/";
export const API_ADMIN_GET_DISTRICT_LIST = "/district/get_district_list/";

// SCHOOL MANAGEMENT API URL'
export const API_ADMIN_SCHOOL_MANAGEMENT_LIST = "/school/list/";
export const API_ADMIN_ADD_SCHOOL = "/school/detail/";
export const API_ADMIN_UPDATE_SCHOOL = "/school/detail/";
export const API_ADMIN_GET_SCHOOL_DETAIL = "/school/detail/";
export const API_ADMIN_GET_SCHOOL_LIST = "/school/get_school_list/";
export const API_ADMIN_SCHOOLINFO_ADD_SCHOOL = "/school/new_school_detail/";
export const API_RESOURCE_GET = "/school/school_resource/";
export const API_RESOURCE_POST = "/school/school_resource/";
export const API_RESOURCE_DOWNLOAD = "/school/download_file/";
export const API_RESOURCE_COMMON_GET = "/school/common_resource_list/";
export const API_KICKOFF_VIDEO_GET = "/school/kickoff_mail/";
export const API_GOLDBALL_VIDEO_GET = "/school/goldball_mail/";
export const API_GRADUATION_VIDEO_GET = "/school/graduation_mail/";
export const API_SCHOOL_USER_CONNECT_LIST = "/users/get_user_list/";
export const API_SCHOOL_RESEND_INVITATION = "/school/resend_invitation/";
export const API_RESOURCE_COMMON_DOCUMENT_GET =
  "/school/common_resource_document_list/";
export const API_RESOURCE_COMMON_SUBCATEGORY_GET =
  "/school/get_subcategory_list/";
export const API_RESOURCE_DOWNLOAD_SUBCATEGORY_VIDEO =
  "/school/download_subcategory_video/";

// CAMPAIGN MANAGEMENT API URL's
export const API_ADMIN_CAMPAIGN_MANAGEMENT_LIST = "/campaign/list/";
export const API_ADMIN_ADD_CAMPAIGN_IMAGE = "/campaign/store_campaign_assets/";
export const API_ADMIN_DELETE_CAMPAIGN_IMAGE =
  "/campaign/store_campaign_assets/";
export const API_ADMIN_CAMPAIGN_MANAGEMENT_POST = "/campaign/detail/";
export const API_ADMIN_CAMPAIGN_MANAGEMENT_GET = "/campaign/detail/";
export const API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE = "/campaign/detail/";
export const API_ADMIN_GET_SUBDOMAIN = "/campaign/get_sub_domain/";
export const API_ADMIN_GET_VALIDATE_SUBDOMAIN =
  "/campaign/validate_sub_domain/";
export const API_ADMIN_GET_CAMPAIGN_ONBOARDLIST = "/campaign/onboarding_list/";
export const API_ADMIN_GET_STARTER_KIT_MAIL = "/school/starter_kit_mail/";
export const API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST =
  "/campaign/campaign_school_list/";
export const API_GET_STUDENT_META_DATA = "/student/meta_student_detail/";

// TEACHER MANAGEMENT API URL's
export const API_ADMIN_TEACHER_MANAGEMENT_LIST = "/teacher/list/";
export const API_ADMIN_TEACHER_MANAGEMENT_GET = "/teacher/detail/";
export const API_ADMIN_TEACHER_MANAGEMENT_UPDATE = "/teacher/detail/";
export const API_ADMIN_TEACHER_MANAGEMENT_DELETE = "/teacher/detail/";
export const API_ADMIN_TEACHER_MANAGEMENT_POST = "/teacher/detail/";
export const API_GET_TEACHER_LIST = "/teacher/get_teacher_list/";
export const API_GET_TEACHER_OVERVIEW = "/teacher/teacher_overview/";
export const API_TEACHER_ASSETS = "/teacher/store_teacher_assets/";
export const API_PETEACHER_OVERVIEW = "/teacher/pe_teacher_overview/";
export const API_VIDEO_SHOWCASE_POST = "/teacher/wizfit_video_showcase_detail/";
export const API_VIDEO_SHOWCASE_LIST = "/teacher/wizfit_video_showcase_list/";
export const API_VIDEO_SHOWCASE_DELETE =
  "/teacher/wizfit_video_showcase_detail/";
export const API_VIDEOSECTION_DETAIL = "/master/get_wizfit_video/";
export const API_EXPORT_TEACHER = "/teacher/teacher_export/";

//STUDENT TASK TRACT FOR VIDEO BADGES
export const API_STUDENT_TASK_TRACK = "/student/student_task_track/";
export const API_STUDENT_BADGES = "/student/earned_badges/";

// STUDENT MANAGEMENT API URL's
export const API_ADMIN_STUDENT_MANAGEMENT_LIST = "/student/list/";
export const API_ADMIN_STUDENT_MANAGEMENT_GET = "/student/detail/";
export const API_ADMIN_STUDENT_MANAGEMENT_UPDATE = "/student/detail/";
export const API_ADMIN_STUDENT_MANAGEMENT_DELETE = "/student/detail/";
export const API_ADMIN_STUDENT_MANAGEMENT_POST = "/student/detail/";
export const API_GET_STUDENT_LIST = "/student/get_student_list/";
export const API_GET_STUDENT_VIDEO_PROGRESS = "/student/watch_history/";
export const API_GET_STUDENT_EVENT_VIDEO_PROGRESS =
  "/student/event_watch_history/";
export const API_GET_STUDENT_LEADERBOARD = "/student/leaderboard/";
export const API_STUDENT_STORE_ASSETS = "/student/store_student_assets/";
export const API_EXPORT_STUDENT = "/student/student_export/";
export const API_EXPORT_STUDENT_DATA = "/student/student_data_export/";

//SPONSORs MANAGEMENT API URL's
export const API_ADMIN_SPONSOR_MANAGEMENT_LIST = "/sponsor/list/";
export const API_ADMIN_SPONSOR_MANAGEMENT_POST = "/sponsor/detail/";
export const API_ADMIN_SPONSOR_MANAGEMENT_UPDATE = "/sponsor/detail/";
export const API_ADMIN_SPONSOR_MANAGEMENT_DELETE = "/sponsor/detail/";
export const API_ADMIN_SPONSOR_MANAGEMENT_GET = "/sponsor/detail/";

//TEAMs MANAGEMENT API URL's
export const API_ADMIN_TEAM_MANAGEMENT_LIST = "/team/list/";
export const API_ADMIN_TEAM_MANAGEMENT_POST = "/team/detail/";
export const API_ADMIN_TEAM_MANAGEMENT_POSTS = "/users/add_user/";
export const API_ADMIN_TEAM_MANAGEMENT_UPDATE = "/team/detail/";
export const API_ADMIN_TEAM_MANAGEMENT_DELETE = "/team/detail/";
export const API_ADMIN_TEAM_MANAGEMENT_GET = "/team/detail/";
export const API_ADMIN_TEAM_MANAGEMENT_GET_ROLE = "/users/user_role_selection";
export const API_ADMIN_TEAM_MANAGEMENT_GET_DATA = "/team/get_team";
export const API_ADMIN_TEAM_CREATE_MANAGEMENT_GET = "/team/team_detail_add";
export const API_ADMIN_TEAM_MANAGEMENT_POST_TEAM = "/team/team_detail_add/";
export const API_ADMIN_TEAM_NAME_MANAGEMENT_LIST = "/team/all_team/ ";
export const API_ADMIN_USER_MANAGEMENT_LIST = "/users/list/";
export const API_ADMIN_TEAM_NAME_LIST = "/team/teams/";
export const API_EVENT_TEAM_PLAYER_LIST_GET = "/team/get_team_players/";
export const API_NEW_TEAM_LIST_GET = "/team/teamdata_list/";
export const API_NEW_TEAM_LIST_POST = "/team/teamdata_detail/";
export const API_NEW_TEAM_LIST_PATCH = "/team/teamdata_detail/";
export const API_NEW_TEAM_LIST_DETAIL = "/team/teamdata_detail/";

//ADMIN DASHBOARD
export const API_ADMIN_DASHBOARD_OVERVIEW = "/admins/overview/";
export const API_ADMIN_DASHBOARD_CAMPAIGN_LEADERBOARD = "/campaign/list/";
export const API_ADMIN_DASHBOARD_DISTRICT_LEADERBORD = "/district/leaderboard/";
export const API_ADMIN_DASHBOARD_SCHOOL_LEADERBOARD = "/school/leaderboard/";
export const API_PUBLIC_DONATION_PAGE_CLASS_LEADERBOARD =
  "/master/class_leaderboard/";

//ADMIN DONATIONS
export const API_ADMIN_DONATION_OVERVIEW = "/donation/overview/";
export const API_ADMIN_DONATION_LIST = "/donation/list/";
export const API_ADMIN_DONATION_GET = "/donation/detail/";
export const API_ADMIN_DONATION_POST = "/donation/detail/";
export const API_ADMIN_DONATION_PATCH = "/donation/detail/";
export const API_ADMIN_DONATION_DELETE = "/donation/detail/";
export const API_PUBLIC_RECENT_DONATION = "/donation/get_recent_donation_list/";
export const API_GET_FISCAL_YEAR_RANGE = "/admins/get_fiscal_year_range/";
export const API_DONATION_STATUS_PLEDGE = "/donation/update_pledge_status/";

//DISTRICT PANEL DASHBOARD
export const API_DISTRICT_PANEL_OVERVIEW = "/district/district_overview/";
export const API_SETUP_STRIPE_CHECKOUT = "/payment/get_OAuth_dashboard_url/";

//SCHOOL PANEL DASHBOARD
export const API_SCHOOL_PANEL_OVERVIEW = "/school/school_overview/";

//BULK UPLOAD
export const API_ADMIN_BULK_UPLOAD_DISTRICT = "/district/bulk_import/";
export const API_ADMIN_BULK_UPLOAD_SCHOOL = "/school/bulk_import/";
export const API_ADMIN_BULK_UPLOAD_TEACHER = "/teacher/bulk_import/";

//PUBLIC DONATION PAGES COMMON API's
export const API_PUBLIC_GET_CAMPAIGN_DETAILS = "/campaign/detail/";
export const API_PUBLIC_MASTER_SEARCH = "/master/search/";
export const API_PUBLIC_GET_SPONSOR_LIST = "/sponsor/public_list/";
export const API_PUBLIC_MAKE_DONATION = "/donation/detail/";
export const API_PUBLIC_GET_DONATION_DETAILS = "/donation/detail/";
export const API_PUBLIC_MASTER_GATEWAY_PERCENTAGE =
  "/master/get_gateway_percentage/";
export const API_PUBLIC_SHARE_FUNDRAISER_TEMPLATE =
  "/mail/email_template_detail/";
export const API_PUBLIC_SHARE_POST_DETAILS = "/mail/share_detail/";

//Prizes
export const API_PRIZE_MASTER_GET = "/prize/list/";
export const API_PRIZE_COMMON_CUSTOM_GET = "/prize/type_detail/";
export const API_PRIZE_STATUS_PATCH = "/prize/prize_detail/";
export const API_PRIZE_PENDING_GET = "/prize/remaining/";
export const API_PRIZE_DISTRIBUTED_GET = "/prize/distribution/";
export const API_PRIZE_DELETE = "/prize/type_detail/";
export const API_PRIZE_TYPE_MODAL = "/prize/type/";
export const API_PRIZE_CHECKBOX_GET = "/prize/list/";
export const API_PRIZE_MASTER_POST = "/prize/prize_detail/";
export const API_PRIZE_COMMON_CUSTOM_POST = "/prize/type_detail/";
export const API_PRIZE_PENDING_LIST_GET = "/prize/remaining/";
export const API_PRIZE_DISTRIBUTION_POST = "/prize/distribution/";
export const API_PRIZE_DISTRIBUTION_DELETE = "/prize/distribution/";
export const API_PRIZE_DISTRIBUTED_GET_DETAIL = "/prize/distribution/";
export const API_PENDING_PRIZE_REPORT = "/prize/download_prize_list/";
export const API_PENDING_PRIZE_STUDENT_MEDAL_REPORT =
  "/prize/student_medal_report/";

//Email settings
export const API_EMAIL_AUTOMATED_LIST = "/mail/email_template_list/";
export const API_EMAIL_MANUAL_LIST = "/mail/email_template_list/";
export const API_EMAIL_MANUAL_POST = "/mail/email_template_detail/";
export const API_EMAIL_AUTOMATED_MANUAL_PATCH = "/mail/email_template_detail/";
export const API_EMAIL_GET_DETAIL = "/mail/email_template_detail/";
export const API_EMAIL_GET_KEYWORDS = "/mail/email_template_keyword_list/";
export const API_DELETE_EMAIL = "/mail/email_template_detail/";
export const API_BULK_EMAIL_GET = "/mail/bulk_email/";
export const API_BULK_EMAIL_SEND_TO_POST = "/mail/bulk_email/";
export const API_BULK_EMAIL_UPDATE = "/mail/update_bulk_email/";
export const API_BULK_EMAIL_SEND_TO_GET = "/mail/bulk_email/";
export const API_EMAIL_TEMPLATE_DETAIL = "/mail/email_template_detail/";
export const API_BULK_EMAIL_AUDIENCE_COUNT = "/mail/get_audition_count/";
export const API_BULK_GAME_EMAIL_POST = "/mail/update_game_email/";
export const API_BULK_GAME_EMAIL_LIST = "/mail/game_email_list/";
export const API_BULK_EMAIL_GAME_AUDIENCE_COUNT = "/mail/get_audience_list/";

export const API_EMAIL_MEDIA_POST = "/mail/media_credentials_detail/";

//PUBLIC DONATION PAGES
export const API_PDP_STUDENT_LIST = "/student/list";
export const INVITE_VIA_MAIL_LIST = "/mail/invite_list/";
export const INVITE_VIA_MAIL_POST = "/mail/invite_detail/";
export const INVITE_VIA_SMS_POST = "/mail/sms_invite_detail/";
export const INVITE_VIA_SOCIAL_SHARE_DETAIL = "/mail/social_share_detail/";
export const INVITE_VIA_MAIL_GET_TEMPLATE = "/mail/email_template_detail/";
export const API_PDP_CHECK_STUDENT_NAME = "/student/check_student_name/";
export const INVITE_VIA_SMS_LIST = "/mail/sms_invite_list/";
export const INVITE_VIA_MAIL_TEACHER_POST = "/mail/teacher_invite_detail/";
export const INVITE_VIA_SMS_TEACHER_POST = "/mail/teacher_sms_invite_detail/";

//resend invite link
export const RESEND_INVITE_MAIL = "/users/resend_login_mail/";

//game management
export const ADMIN_API_GAME_MANAGEMENT_LIST = "/game/list/";
export const ADMIN_GET_TEAM_LIST = "/game/team_list/";
export const ADMIN_TICKET_TYPE_LIST = "/game/ticket_type_list/";
export const ADMIN_GAMES_POST_API = "/game/deatil/";
export const ADMIN_GAMES_GET_DETAIL = "/game/deatil/";
export const SCHOOL_DASHBOARD_CHECK_API =
  "/school/get_school_dashboard_selection/";
export const GAME_TICKET_INFO_DETAIL_API = "/game/ticket_sold_detail/";
export const GAME_TICKET_INFO_POST_API = "/game/ticket_sold_detail/";
export const GAME_QUOTA_TICKET_INFO_GET_API = "/game/game_quotas/";
export const GAME_QUOTA_TICKET_INFO_PATCH_API = "/game/game_quotas/";
export const GAME_QUOTA_PRICE_INFO_GET_API = "/game/gamequota_items/";
export const GAME_QUOTA_PRICE_INFO_PATCH_API = "/game/gamequota_items/";
export const GAME_CALENDAR_API = "/calendar/get_game_data/";
export const CAMPAIGN_CALENDAR_API = "/calendar/get_campaign_data/";
export const ADMIN_GAMES_DELETE_API = "/game/deatil/";
export const API_ADMIN_GAME_PRODUCT_NAME_LIST = "/game/game_ticket_list/";
export const API_POST_GAME_VOUCHER = "/game/gamevoucher_detail/";
export const API_PATCH_GAME_VOUCHER = "/game/gamevoucher_detail/";
export const API_GET_GAME_VOUCHER = "/game/gamevoucher_detail/";
export const API_GET_LIST_GAME_VOUCHER = "/game/gamevoucher_list/";
export const API_CREATE_VOUCHER = "/game/create_vouchers/";
export const API_ADMIN_GET_GAME_ONBOARDLIST = "/game/games_onboarding_list/";
export const API_UPLOAD_GAME_RESOURCE = "/game/game_resource/";
export const API_GET_GAME_RESOURCE = "/game/game_resource/";
export const API_DOWNLOAD_GAME_RESOURCE = "/game/game_resource_download_file/";
export const API_ADD_ASSEMBLY_CALENDAR = "/game/add_assembly_to_calendar/";
export const API_ADD_HOTEL_CALENDAR = "/game/add_hotel_to_calendar/";
export const API_GAME_RESOURCE_COMMON_DOCUMENT_GET =
  "/game/game_resource_document_list/";
export const API_GAME_RESOURCE_COMMON_SUBCATEGORY_GET =
  "/game/get_subcategory_list/";
export const API_GAME_RESOURCE_DOWNLOAD_SUBCATEGORY_VIDEO =
  "/game/download_subcategory_video/";
export const API_GAME_HOST_TICKET_SOLD = "/game/get_school_ticket_sold_list/";

//game panel sponsor
export const GAME_API_SPONSOR_LIST = "/game/sponsor_list/";
export const GAME_API_SPONSOR_POST = "/game/sponsor_detail/";
export const GAME_API_SPONSOR_DETAIL = "/game/sponsor_detail/";
export const GAME_API_SPONSOR_UPDATE = "/game/sponsor_detail/";
export const GAME_API_SPONSOR_DELETE = "/game/sponsor_detail/";

//Game panel assembly schedule
export const ASSEMBLY_SCHEDUAL_API_LIST = "/game/assemble_list/";
export const ASSEMBLY_SCHEDUAL_API_GET = "/game/assemble_detail/";
export const ASSEMBLY_SCHEDUAL_API_POST = "/game/assemble_detail/";
export const ASSEMBLY_SCHEDUAL_API_PATCH = "/game/assemble_detail/";
export const ASSEMBLY_SCHEDUAL_API_DELETE = "/game/assemble_detail/";
export const PAID_ASSEMBLY_SCHEDUAL_API_POST = "/game/paid_assemble_detail/";
export const PAID_ASSEMBLY_SCHEDUAL_API_PATCH = "/game/paid_assemble_detail/";
export const PAID_ASSEMBLY_SCHEDUAL_API_DETAIL = "/game/paid_assemble_detail/";
export const PAID_ASSEMBLY_SCHOOL_POST_API =
  "/game/assemble_school_group_detail/";
export const PAID_ASSEMBLY_SCHOOL_PATCH_API =
  "/game/assemble_school_group_detail/";
export const PAID_ASSEMBLY_SCHOOL_GET_API =
  "/game/assemble_school_group_detail/";
export const PAID_ASSEMBLY_SCHOOL_DELETE_API =
  "/game/assemble_school_group_detail/";
//Game panel volunteer
export const VOLUNTEER_API_LIST = "/game/volunteer_list/";
export const VOLUNTEER_API_GET = "/game/volunteer_detail/";
export const VOLUNTEER_API_POST = "/game/volunteer_detail/";
export const VOLUNTEER_API_PATCH = "/game/volunteer_detail/";
export const VOLUNTEER_API_DELETE = "/game/volunteer_detail/";
export const AREA_OF_FOCUS_CATEGORIES = "/game/checklist_category_list/";

//game panel Home Team
export const API_HOME_TEAM_LIST = "/game/hometeam_list/";
export const API_HOME_TEAM_POST = "/game/hometeam_detail/";
export const API_HOME_TEAM_PATCH = "/game/hometeam_detail/";
export const API_HOME_TEAM_GET = "/game/hometeam_detail/";
export const API_HOME_TEAM_DELETE = "/game/hometeam_detail/";
export const API_BULK_IMPORT = "/game/bulk_import/";

//game panel for game committee
export const API_GAME_COMMITTEE_LIST = "/game/game_committee_list/";
export const API_GAME_COMMITTEE_DELETE = "/game/game_committee_detail/";
export const API_GAME_COMMITTEE_GET_DETAIL = "/game/game_committee_detail/";
export const API_GAME_COMMITTEE_POST = "/game/game_committee_detail/";

//Checklist game panel
export const API_CHECKLIST_THINGS_TO_DO = "/game/game_checklist/";
export const API_CHECKLIST_STATUS_UPDATE = "/game/checklist_detail/";
export const API_CHECKLIST_INTRO_EMAIL = "/game/checklist_action_date_detail/";

//game panel hote info
export const API_HOTEL_INFO_POST = "/game/hotel_detail/";
export const API_HOTEL_INFO_PATCH = "/game/hotel_detail/";
export const GAME_HOTEL_GET_API = "/game/hotel_detail/";
export const GAME_HOTEL_GET_LIST_API = "/game/hotel_list/";
export const GAME_HOTEL_DELETE_API = "/game/hotel_detail/";

//game dashboard
export const API_GAME_OVERVIEW_DASHBOARD = "/game/get_dashboard/";

//Hotel and Assembly List
export const API_ALL_HOTEL_LIST = "/game/all_hotel_list/";
export const API_ALL_ASSEMBLY_LIST = "/game/all_assemble_list/";

//donation report api
export const API_DONATION_REPORT_GENERATE = "/donation/get_donation_report/";

//note section
export const NOTE_SECTION_GET_API = "/game/note_detail/";
export const NOTE_SECTION_POST_API = "/game/note_detail/";
export const NOTE_SECTION_PATCH_API = "/game/note_detail/";
export const NOTE_SECTION_DELETE_API = "/game/note_detail/";
export const NOTE_SECTION_GET_LIST_API = "/game/note_list/";

//game panel graph
export const GAME_GRAPH_API = "/game/get_graph_data/";
export const GAME_TABLE_API = "/game/get_table_data/";
export const GAME_DOOR_SALE_PATCH_API = "/game/door_sale_number/";
export const GAME_DOOR_SALE_GET_API = "/game/door_sale_number/";

//get gallery api and repositor
export const GET_GALLEY_ASSETS = "/school/get_gallery_repository/";
export const GET_REPOSITORY_ASSETS = "/school/get_gallery_repository/";
export const PATCH_API_ADDING_STUDENT_ASSET_TO_CAMPAIGN =
  "/student/store_student_assets/";
export const GET_API_EMAIL_CONFIRMATION =
  "/mail/email_notification_confirmation/";
export const GET_API_SMS_CONFIRMATION = "/mail/sms_notification_confirmation/";

//download and upload game
export const GET_API_FB_DOWNLOAD_GAME = "/game/export_fb_boosted_games/";
export const API_UPDATE_VIDEO_URL = "/game/update_video_urls/";

//CALENDAR MANAGEMENT API URL's
export const API_CALENDAR_GET_DATA = "/calendar/";
export const API_CALENDAR_WEEK_GET_DATA = "/calendar/week/";
export const API_CALENDAR_POST_TASK_DATA = "/calendar/";
export const API_CALENDAR_EVENT_CATEGORY_LIST =
  "/calendar/event-category/list/";
export const API_PTO_GET_DATA = "/calendar/";
export const API_PAID_EVENT_POST_DATA = "/calendar/paid_event_detail/";
export const API_PAID_EVENT_PATCH_DATA = "/calendar/paid_event_detail/";
export const API_PAID_EVENT_GET_DATA = "/calendar/paid_event_detail/";
export const API_SCHOOL_CALENDAR_GET_DATA = "/calendar/get_school_dates/";
export const API_GET_LIST_CALENDAR = "/calendar/list_calendar/";
export const API_PLAYER_AVAILABILITY = "/calendar/get_available_players/";
export const API_PAID_EVENT_DELETE_DATA = "/calendar/paid_event_detail/";
export const API_PAID_EVENT_LIST = "/calendar/paid_event_list/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_POST = "/calendar/eventslot/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_GET = "/calendar/eventslot/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_POST_DETAIL =
  "/calendar/eventslot_detail/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH_DETAIL =
  "/calendar/eventslot_detail/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH = "/calendar/eventslot/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_DELETE = "/calendar/eventslot/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_LIST = "/calendar/eventslot_list/";
export const API_PLAYER_AVAILBLE_EVENT_SLOT_DETAIL_LIST =
  "/calendar/eventslot_detail_list/";
export const API_PLAYER_AVAILBLE_MONTHLY_SLOT_LIST =
  "/calendar/monthly_slot_list/";
